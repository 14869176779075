<template>
  <div>
    <b-overlay
      id="overlay-bg-product-list"
      class="custom-overlay-icon-top"
      :show="loading"
      variant="white"
      opacity=".5"
      blur="2px"
      rounded="sm"
    >
      <b-card
        no-body
        class="mb-0 pb-0"
      >
        <div class="py-1 px-2 d-flex justify-content-between">
          <div
            class="text-primary font-weight-bolder"
            v-html="'Tìm thấy ' + (totalProducts || 0) + ' chiến dịch'"
          />
          <table-column-toggle
            id="refCampaignListTable"
            :table-columns="tableColumns"
            @update-columns-show="updateTableColumns"
          />
        </div>
        <b-table
          ref="refProductListTable"
          class="position-relative custom-head thead-no-wrap line-height-12"
          responsive
          hover
          primary-key="id"
          show-empty
          empty-text="Không có kết quả nào"
          :items="products"
          :fields="tableColumns"
          @row-clicked="rowClicked"
        >
          <template #cell(id)="data">
            <span class="d-block font-weight-bold">{{ data.item.id }}</span>
          </template>

          <!--          <template #cell(status)="data">-->
          <!--            <span-->
          <!--              class="d-block font-weight-bold text-primary"-->
          <!--              :class="'text-' + data.item.status"-->
          <!--            >{{ (CAMPAIGN_STATUS.find(item => item.value === data.item.status) || {}).text || data.item.status }}</span>-->
          <!--          </template>-->

          <template #cell(name)="data">
            <span class="text-primary">{{ data.item.name }}</span>
          </template>

          <template #cell(start_at)="data">
            {{ formatDate(data.item.start_at) }}
          </template>

          <template #cell(end_at)="data">
            {{ formatDate(data.item.end_at) }}
          </template>

          <template #cell(check)="data">
            <span class="text-primary font-weight-bolder">{{
              data.item.checked_count + ' / ' + data.item.product_count
            }}</span>
          </template>

        </b-table>

        <!--        <div class="mb-3">-->
        <!--          <b-row>-->
        <!--            &lt;!&ndash; Per Page &ndash;&gt;-->
        <!--            <b-col-->
        <!--              cols="12"-->
        <!--              sm="6"-->
        <!--              class="d-flex align-items-center justify-content-center justify-content-sm-start mb-1 mb-md-0"-->
        <!--            >-->
        <!--              <v-select-->
        <!--                v-model="perPage"-->
        <!--                :options="perPageOptions"-->
        <!--                :clearable="false"-->
        <!--                class="per-page-selector d-inline-block mx-50"-->
        <!--              />-->
        <!--              <label>mỗi trang</label>-->
        <!--            </b-col>-->

        <!--            &lt;!&ndash; Pagination &ndash;&gt;-->
        <!--            <b-col-->
        <!--              cols="12"-->
        <!--              sm="6"-->
        <!--              class="d-flex align-items-center justify-content-center justify-content-sm-end"-->
        <!--            >-->
        <!--              <b-pagination-->
        <!--                v-model="currentPage"-->
        <!--                :total-rows="totalProducts"-->
        <!--                :per-page="perPage"-->
        <!--                first-number-->
        <!--                last-number-->
        <!--                class="mb-0 mt-1 mt-sm-0"-->
        <!--                prev-class="prev-item"-->
        <!--                next-class="next-item"-->
        <!--              >-->
        <!--                <template #prev-text>-->
        <!--                  <feather-icon-->
        <!--                    icon="ChevronLeftIcon"-->
        <!--                    size="18"-->
        <!--                  />-->
        <!--                </template>-->
        <!--                <template #next-text>-->
        <!--                  <feather-icon-->
        <!--                    icon="ChevronRightIcon"-->
        <!--                    size="18"-->
        <!--                  />-->
        <!--                </template>-->
        <!--              </b-pagination>-->
        <!--            </b-col>-->
        <!--          </b-row>-->
        <!--        </div>-->
      </b-card>
    </b-overlay>
    <!-- Filters -->
    <!--    <Filters-->
    <!--      v-model="filter"-->
    <!--      :loading.sync="loading"-->
    <!--      @search="handleSearch"-->
    <!--      @clear-filter="handleClearFilter"-->
    <!--    />-->
    <!--    table-->

    <b-modal
      id="modal-campaign-detail"
      ref="modal-campaign-detail"
      cancel-title="Hủy"
      ok-title="Xác nhận"
      size="lg"
      :no-close-on-backdrop="true"
      centered
      header-text-variant="danger"
      :title="'Chi tiết chiến dịch ' + currentProduct.name"
      @ok="handleDeleteProduct"
    >
      <b-card-text>
        <div class="detail-item">
          <div class="detail-label">
            Tên chiến dịch
          </div>
          <span class="detail-text">
            {{ currentProduct.name }}
          </span>
        </div>
        <div class="detail-item">
          <div class="detail-label">
            Tên chiến dịch
          </div>
          <span class="detail-text">
            {{ currentProduct.name }}
          </span>
        </div>
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-export-excel"
      ref="modal-export-excel"
      cancel-title="Hủy"
      ok-title="Xuất excel"
      size="lg"
      :no-close-on-backdrop="true"
      centered
      header-text-variant="primary"
      title="Chọn thời gian xuất Excel"
      @ok="handleExportExcel"
    >
      <b-card-text>
        <b-row>
          <b-col
            cols="12"
            sm="6"
          >
            <b-form-group label="Thời gian">
              <b-form-radio-group
                v-model="exportExcelTime"
                stacked
              >
                <b-form-radio value="week">
                  Theo tuần (7 ngày qua)
                </b-form-radio>
                <b-form-radio value="month">
                  Theo tháng (30 ngày qua)
                </b-form-radio>
                <b-form-radio value="">
                  Tất cả thời gian
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            sm="6"
          >
            <b-form-group label="Close Deal">
              <b-form-radio-group
                v-model="exportExcelCloseDeal"
                stacked
              >
                <b-form-radio value="">
                  Tất cả
                </b-form-radio>
                <b-form-radio value="active">
                  Close Deal
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group>
              <label>Từ</label>
              <flat-pickr
                id="move_in_at"
                v-model="timeFrom"
                class="form-control"
                :config="{ altFormat: 'd-m-Y', altInput: true, mode: 'single', allowInvalidPreload: true, dateFormat: 'Y-m-d', allowInput: true }"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <b-form-group>
              <label>Đến</label>
              <flat-pickr
                id="move_in_at"
                v-model="timeTo"
                class="form-control"
                :config="{ altFormat: 'd-m-Y', altInput: true, mode: 'single', allowInvalidPreload: true, dateFormat: 'Y-m-d', allowInput: true }"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-text>
    </b-modal>

    <actions
      :buttons="actionsButton"
      @click="handleActionsButton"
    />
  </div>
</template>

<script>
import {
  BAvatar, BCard, BCol, BMediaAside, BPagination, BRow, BTable, VBTooltip, BBadge, BButton, BOverlay,
  BCardText, BFormGroup, BFormRadioGroup, BFormRadio,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import productStoreModule from '@/views/product/productStoreModule'
import TableColumnToggle from '@core/components/TableColumnToggle.vue'
import ability from '@/libs/acl/ability'
import { subject } from '@casl/ability'
import Actions from '@core/components/fixed-button/Fixed-Right-Button.vue'
import flatPickr from 'vue-flatpickr-component'
import campaignStoreModule from '@/views/campaign/campaignStoreModule'
import http from '@/global/http'
import useCampaignList from './useCampaignList'
import Filters from './Filters.vue'

export default {
  components: {
    Filters,
    BAvatar,
    BCard,
    BTable,
    TableColumnToggle,
    BPagination,
    vSelect,
    BRow,
    BCol,
    BCardText,
    BMediaAside,
    BBadge,
    BOverlay,
    BButton,
    Actions,
    BFormGroup,
    BFormRadioGroup,
    BFormRadio,
    flatPickr,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      loading: false,
      ability,
      subject,
    }
  },
  computed: {
    actionsButton() {
      const validActions = []
      if (ability()
        .can('export-excel')) {
        validActions.push(this.ACTIONS_BUTTON.EXPORT_EXCEL)
      }
      return validActions
    },
  },
  watch: {
    products() {
      setTimeout(() => {
        this.$refs.refProductListTable.refresh()
        console.log('refresh table data')
      }, 200)
    },
  },
  created() {
    const { filter } = this.$route.query
    Object.assign(this.filter, JSON.parse(filter || '""'))
    store.dispatch('app/fetchUsers')
    this.filter.product_type = 'townhouse'
    this.fetchProducts()
      .finally(() => {
        this.loading = false
      })
    // waiting for watching done
    setTimeout(() => {
      this.created = true
    }, 500)
  },
  methods: {
    gotoProductEdit(product) {
      this.$router.push({
        name: `product-${this.productMenuType}-edit`,
        params: { id: product.id },
      })
    },
    handleActionsButton(btn) {
      if (btn.value === 'excel') {
        this.$refs['modal-export-excel'].show()
      }
    },
    handleExportExcel() {
      this.$toastr('Đang xuất file, vui lòng chờ giây lát!')
      this.$call(this.exportExcelProduct()
        .then(res => {
          this.downloadUrl(res.data, 'excel')
        }))
    },
    openModalConfirmDeleteProduct(product) {
      console.log('modal-confirm-delete-product')
    },
    handleDeleteProduct() {
      this.$call(this.deleteProduct(this.currentProduct.id), true)
        .then(() => {
          const foundIdx = this.products.findIndex(item => item.id === this.currentProduct.id)
          if (foundIdx !== -1) {
            this.products.splice(foundIdx, 1)
          }
        })
    },
    gotoProductDetail({ id }) {
      const route = this.$router.resolve({
        name: `product-${this.productMenuType}-detail`,
        params: { id },
      })
      window.open(route.href, this.$store.state.app.isDev ? '_self' : '')
    },
    gotoCampaignDetail({ id }) {
      const route = this.$router.resolve({
        name: 'campaign-detail',
        params: { id },
      })
      window.open(route.href, this.$store.state.app.isDev ? '_self' : '')
    },
    handleSearch() {
      this.$router.replace({
        query: {
          filter: JSON.stringify(this.filter || ''),
        },
      })
      this.fetchProducts()
        .finally(() => {
          this.loading = false
        })
    },
    handleClearFilter(initFilter) {
      this.clearFilter(initFilter)
    },
    updateTableColumns(tableColumns) {
      this.tableColumns = tableColumns
    },
    rowClicked(row) {
      this.gotoCampaignDetail(row)
    },
  },
  setup() {
    const CAMPAIGN_STORE_MODULE_NAME = 'campaign'
    const PRODUCT_STORE_MODULE_NAME = 'product'
    if (!store.hasModule(CAMPAIGN_STORE_MODULE_NAME)) store.registerModule(CAMPAIGN_STORE_MODULE_NAME, campaignStoreModule)
    onUnmounted(() => {
      if (store.hasModule(CAMPAIGN_STORE_MODULE_NAME)) store.unregisterModule(CAMPAIGN_STORE_MODULE_NAME)
    })

    if (!store.hasModule(PRODUCT_STORE_MODULE_NAME)) store.registerModule(PRODUCT_STORE_MODULE_NAME, productStoreModule)
    onUnmounted(() => {
      if (store.hasModule(PRODUCT_STORE_MODULE_NAME)) store.unregisterModule(PRODUCT_STORE_MODULE_NAME)
    })

    return {
      ...useCampaignList(),
    }
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-flatpicker.scss';

.per-page-selector {
  width: 90px;
}

.custom-head th {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.table .feather {
  min-width: 1rem;
}

.product-badge {
  position: absolute;
  right: 0;
  font-size: .7rem !important;

  &.hot {
    top: .25rem;
  }

  &.corner {
    bottom: .25rem;
  }

  &.hidden-phone {
    top: .25rem;
  }
}

.product-actions-wrapper {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;

  > * {
    padding: .25rem !important;
  }
}
</style>
